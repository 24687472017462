import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { css } from 'emotion'
import ref from '../img/REF.svg'

import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

import '../../node_modules/react-accessible-accordion/dist/fancy-example.css'

const main = css({
  'label': 'ref',

  '.ref': {
    padding: '3rem 0',
    fontWeight: 'bold',
    background: '#937EB6',
    color: '#fff',

    p: {
      padding: '0 0.2em'
    }
  },

  '@keyframes blink': {
    '0%': { opacity: 0.2 },
    '15%': { opacity: 0.3 },
    '35%': { opacity: 0.5 },
    '45%': { opacity: 0.6 },
    '65%': { opacity: 0.8 },
    '85%': { opacity: 0.9 },
    '99%': { opacity: 1 }
  },

  '.blink': {
    animationName: 'blink',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationDirection: 'alternate',
  },

});

export const RefTemplate = ({ content, contentComponent, helmet }) => {
  const PageContent = contentComponent || Content

  return (
    <section className={main}>
      {helmet || ''}


      <div className="content">
        <div className="columns">
          <div className="column is-10 is-offset-1">

            <img src={ref} alt="ref" className="" />
            <Link to="/registro-ref">
              <div className="ref has-text-centered notification blink">
                <p>¿Querés ser parte de la REF? Registrate en el siguiente enlace.</p>
              </div>
            </Link>

            <div className="content">
              <section className="section">

                <PageContent className="content" content={content} />

                <div className="columns">
                  <div className="column">
                    <Link className="button is-flex is-medium is-primary is-light" to="/red-economica-feminista/productosyservicios">
                      <span role="img" aria-label="encontrar">🔎</span> Buscar Productos & Servicios <span role="img" aria-label="shopping">🛍️</span>
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="button is-flex is-medium is-success is-light" to="/red-economica-feminista/empresarias">
                      <span role="img" aria-label="encontrar">🔎</span> Buscar Empresarias <span role="img" aria-label="shopping" aria-label="shopping">👩‍💻</span>
                    </Link>
                  </div>
                </div>

              </section>
            </div>

          </div>
        </div>
      </div>

    </section>
  )
}


RefTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object
}

const REF = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <RefTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        helmet={
          <SEO
            isBlogPost={false}
            postData={{
              description: '¡Buscá productos y servicios provenientes de chicas feministas o forma parte de la red!',
              title: 'Red Económica Feminista',
              slug: '/red-economica-feminista'
            }}
            postImage={'/img/ref-logo.jpg'}
          />}
      />
    </Layout>
  )
}

REF.propTypes = {
  data: PropTypes.object.isRequired,
}

export default REF

export const REFQuery = graphql`
  query REF($id: String!) {
    markdownRemark(id: {eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
  `
